import axios from "axios";

export const API = axios.create({
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*"
  }
});

export function setAuthToken(token) {
  API.defaults.headers["x-access-token"] = token;
}
