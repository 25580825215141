export const DEFAULT_DECIMAL_SCALE = 2;
export const DEFAULT_NUMBER_FORMAT = "us";

export const NUMBER_FORMAT_LOCALE = {
    in: "en-IN",
    es: "es-ES",
    fr: "fr-FR",
    us: "en-US"
};

export function getNumberLocale(numberFormat) {
    return NUMBER_FORMAT_LOCALE[numberFormat] || NUMBER_FORMAT_LOCALE[DEFAULT_NUMBER_FORMAT];
}

export function formatNumberWithLocale(number, decimalScale = DEFAULT_DECIMAL_SCALE, numberFormat = DEFAULT_NUMBER_FORMAT) {
    const locale = getNumberLocale(numberFormat);
    return new Number(number).toLocaleString(locale, { minimumFractionDigits: decimalScale });
}

export function formatNumber(number, decimalScale = DEFAULT_DECIMAL_SCALE, numberFormat = DEFAULT_NUMBER_FORMAT) {
    return formatNumberWithLocale(number, decimalScale, numberFormat);
}

export function formatNumberWithCurrency(numberWithCurrency, decimalScale = DEFAULT_DECIMAL_SCALE, numberFormat = DEFAULT_NUMBER_FORMAT) {
    const tokens = numberWithCurrency.split(" ");
    const currency = tokens[0];
    const number = tokens[1];
    const formattedNumber = formatNumber(number, decimalScale, numberFormat);
    return `${currency} ${formattedNumber}`;
}


