const style = {};
const sidebarWidth = '250';

style.menu = {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    bottom: 0,
    left: 0,
    width: sidebarWidth,
    background: '#1B1C1D',
    overflowX: 'hidden',
};

style.sidebarMain = {
    marginLeft: sidebarWidth,
    minWidth: parseInt(sidebarWidth, 10) + 300,
};

style.main = {
    ...style.sidebarMain,
    maxWidth: parseInt(sidebarWidth, 10) + 900,
};
style.mainDiv = {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    flex: 1,
    overflowX: 'hidden',
};
style.sideBar = {
    background: '#1b1c1d',//26418f EF5350
    width: '15em',
    overflow: 'hidden',
};
style.companyDiv = {
    display:'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '1.5em',
    paddingTop: '1.5em',
    paddingLeft: '1.2em'
}
style.companyTitle = {
    marginTop: 0,
    fontSize: '1.3rem',
    fontWeight: 'bolder',
    color:'white'
}
style.toolbar = {
    width: '100vw',
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '1.2em',
    paddingLeft: '2em',
    paddingRight: '2em',
    border: '0 0 2px black',
    boxShadow: '0 0 2px grey',
    backgroundColor: '#ffffff',
};
style.dashboardDiv = {
    width: 'calc(100vw - 15em)',
    height: '100vh',
    flex: 1,
    overflowX: 'hidden',
    paddingTop: '2em',
    paddingLeft: '2em',
    paddingRight: '0.6em',
    backgroundColor: '#eef1f6',
};

style.header = {
    backgroundColor: '#f8f9fa',
    paddingLeft: '8em',
    paddingBottom: '0em',
    marginTop: '1em',
    width: '100%',
    height: '100%',
};

style.tableHeader = {
    backgroundColor: '#e1f5fe',
    color: 'black',
    fontWeight: '800',
};
style.font = {
    fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\', \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\', sans-serif',
    fontSize: '16px',
    fontWeight: '500',
};

style.toolbarDiv = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
};

style.subToolbarDiv = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
};

style.subSideBar = {
    background: '#FFEBEE',
    width: '8vw'
};
style.sidebarDiv = {
    display: 'grid', paddingTop: '1rem', paddingBottom: '1rem',
    color:'white',
    fontSize:'1rem',
};
style.subSidebarDiv = {
    justifyContent: 'center',
    display: 'flex',
    paddingTop: 10,
    paddingBottom: 10,
    color: 'black',
};
style.parentDiv = {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    marginTop: '1.5em',
    marginRight: '1.5em',
}
style.cardMainDiv = {
    boxShadow: '5px 5px 5px 5px #F0F0F0',
    width:'25vw'
}
style.parentContentView = {
    width: '80.5vw',
    height: '75vh',
    position: 'inherit',
    marginRight: '2em'
}

style.invoiceHeaderDiv = {
    alignItems:'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
}
style.subSectionMainDiv = {
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
}

style.paytmCardMainDiv = {
    boxShadow: '5px 5px 5px 5px #F0F0F0',
    width:'600px'
}

style.paytmCardMetaDiv = {
    float: 'right',
    color: 'red'
}

style.accountCardDescription = {
    paddingTop: '3%'
}

style.connectCardButton = {
    marginTop: '1.5%'
}

style.paytmCardNewUserButton = {
    backgroundColor: "transparent",
    color: "#2185d0"
}

style.cashfreeNewUserButton = {
    backgroundColor: "transparent",
    color: "#2185d0",
    marginLeft: '-10px'
}

style.newUserMeta = {
    color: "red"
}

style.themeTableHeader = {
    backgroundColor: '#EF5350',
    color: 'white',
    fontWeight: '800',
};

style.signOutPopup = {
    cursor: 'pointer', 
    fontSize:'large'
}
export default style;
