import {useEffect, useState} from "react";
import {API} from "src/api";
import querystring from "querystring";
import Overlay from "src/components/Overlay";
import {useDispatch} from "react-redux";
import {addToast} from "src/redux/actions/toasts";
import {useSelector} from "react-redux";
import {formatNumberWithCurrency} from "src/numberUtil";
import clsx from "clsx";
import {Button, Dimmer, Dropdown, Icon, Label, Loader, Pagination, Segment, Table} from "semantic-ui-react";
import Style from "src/Style";
import 'semantic-ui-css/semantic.min.css'


function getAccountName(account, index) {
  return account.name ? account.name : "Stripe Account " + (index + 1);
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function Invoices({invoices, updateInvoices}) {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [invoiceActive, setInvoiceActive] = useState(false);
  const [stripeAccounts, setStripeAccounts] = useState([]);
  const currentUser = useSelector(function (state) {
    return state.auth.user;
  });
  const emptyFormData = {
    documentCode: "",
    documentSeqCode: "",
    stripeAccountId: "",
    customerEmail: "",
    isUpdateEmail: false,
    isAutocharge: false
  };

  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState(emptyFormData);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [dimmerLoader, setDimmerLoader] = useState(false);
 const [invoiceList, setInvoiceList] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [loading, setLoading] = useState(true);

  function handleFormChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({...formData, [name]: value});
  }

  async function handleFormSubmit(e) {
    e.preventDefault();
    setFormErrors({});
    let error = false;
    if (formData.stripeAccountId === "") {
      setFormErrors(function (formErrors) {
        return {...formErrors, stripeAccountId: "Stripe Account cannot be empty"};
      });
      error = true;
    }
    if (!validateEmail(formData.customerEmail)) {
      setFormErrors(function (formErrors) {
        return {...formErrors, customerEmail: "Customer email is invalid"};
      });
      error = true;
    }
    if (error) {
      return;
    }
    try {
      setFormSubmitting(true);
      const sendFormData = {
        documentCode: formData.documentCode,
        customerEmail: formData.customerEmail,
        stripeAccountId: formData.stripeAccountId,
        isUpdateEmail: encodeURIComponent(formData.isUpdateEmail),
        isAutocharge: formData.isAutocharge
      }
      await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/newinvoice?" + querystring.stringify(sendFormData));
      setInvoiceActive(false);
      setFormData(emptyFormData);
      dispatch(addToast({type: "alert-success", text: "Invoice was sent to Stripe"}));
      updateInvoices();
      getInvoicesList();
    } catch (err) {
      dispatch(addToast({type: "alert-danger", text: "Failed to send invoice"}));
    }
    setFormSubmitting(false);
  }

  async function handleSend(e) {
    e.preventDefault();
    let oldInvoice = invoiceList[e.currentTarget.value];
    let oldFormData = formData;
    oldFormData.customerEmail = oldInvoice.customerEmail;
    oldFormData.documentCode = oldInvoice.salesInvoiceCode;
    oldFormData.documentSeqCode = oldInvoice.documentSeqCode;
    setFormData(oldFormData);
    setInvoiceActive(true);
  }

  function closeInvoiceModal(e) {
    e.preventDefault();
    setFormData(emptyFormData);
    setFormErrors({});
    setInvoiceActive(false);
  }

  useEffect(function () {
    async function fetchData() {
      try {
        const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/stripe/accounts");
        setStripeAccounts(resp.data);
      } catch {
        setError("Failed to fetch accounts");
      }
    }
    fetchData();
  }, []);

  async function getInvoicesList() {
    let page = pageNumber === -1 ? 0 : pageNumber;

      await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/allinvoices?page="+page)
        .then(data => {
          setInvoiceList(data.data.content);
          setPageNumber(page);
          setTotalPages(data.data.totalPages);
          setLoading(false);
          setDimmerLoader(false);
        })
        .catch(err => {
          setLoading(false);
          setDimmerLoader(false);
        })
  }

  useEffect(() => {
    if (pageNumber > -1) {
      setDimmerLoader(true);
      getInvoicesList();
    }
  }, [pageNumber]);

  function getInvoiceModal() {
    return (
      <div
        style={{
          zIndex: 1000,
          position: "absolute",
          width: "100vw",
          height: "100vh",
          display: "flex",
          top: 0,
          left: 0,
          alignItems: "center",
          justifyContent: "center"
        }}>
        <div
          className='card'
          style={{width: 480, color: "#192038", border: "none", boxShadow: "0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)"}}>
          <div className='card-header px-5' style={{background: "white"}}>
            <h5 className='m-0'>Send Invoice</h5>
          </div>
          <div className='card-body px-5'>
            <form>
              <div className='form-group'>
                <label for='exampleInputPassword1' className='form-label'>
                  Invoice Code
                </label>
                <input
                  name='documentSeqCode'
                  type='text'
                  className='form-control'
                  id='exampleInputPassword1'
                  value={formData.documentSeqCode}
                  onChange={handleFormChange}
                  readOnly
                />
              </div>
              <div className='form-group'>
                <label for='stripeAccountId' className='form-label'>
                  Stripe Account
                </label>
                <select
                  className={clsx("custom-select", formErrors.stripeAccountId && "is-invalid")}
                  aria-label='Default select example'
                  onChange={handleFormChange}
                  id='stripeAccountId'
                  name='stripeAccountId'>
                  <option selected value=''>
                    Choose a Stripe Account
                  </option>
                  {stripeAccounts.map(function (account, index) {
                    return <option value={account.id}>{getAccountName(account, index)}</option>;
                  })}
                </select>
                {formErrors.stripeAccountId && (
                  <small id='stripeAccountId-error' className='form-text text-danger'>
                    {formErrors.stripeAccountId}
                  </small>
                )}
              </div>
              <div className='form-group form-check'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  id='isAutocharge'
                  name='isAutocharge'
                  onChange={handleFormChange}
                  checked={formData.isAutocharge}
                />
                <label for='isAutocharge' className='form-label'>
                  Auto Charge Customer
                </label>
              </div>
              <div className='form-group'>
                <label for='exampleInputEmail1' className='form-label'>
                  Customer Email Address
                </label>
                <input
                  type='email'
                  name='customerEmail'
                  className={clsx("form-control", formErrors.customerEmail && "is-invalid")}
                  id='exampleInputEmail1'
                  aria-describedby='emailHelp'
                  value={formData.customerEmail}
                  onChange={handleFormChange}
                />
                {formErrors.customerEmail && (
                  <small id='customerEmail-error' className='form-text text-danger'>
                    {formErrors.customerEmail}
                  </small>
                )}
              </div>
              <div className='form-group form-check'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  id='isUpdateEmail'
                  name='isUpdateEmail'
                  onChange={handleFormChange}
                  checked={formData.isUpdateEmail}
                />
                <label for='isUpdateEmail' className='form-label'>
                  Update User Email
                </label>
              </div>
            </form>
          </div>
          <div className='modal-footer'>
            <button className='btn btn-outline-secondary' onMouseDown={closeInvoiceModal}>
              Cancel
            </button>
            <button className='btn btn-primary' onMouseDown={handleFormSubmit} disabled={formSubmitting}>
              {formSubmitting ? (
                <div className='spinner-border spinner-border-sm mx-3' role='status'>
                  <span className='sr-only'>Sending...</span>
                </div>
              ) : (
                "Send"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }


  const invoiceTable = () => (
    <div className='tableSection'>
      {(
        <div>
          <Dimmer.Dimmable as={Table} color='red' celled key='red' singleLine>
            <Table.Header>
              <Table.Row>
                <Dimmer inverted active={dimmerLoader}>
                  <Loader>Fetching invoices...</Loader>
                </Dimmer>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell textAlign='center' style={Style.tableHeader}>
                  Document Code
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center' style={Style.tableHeader}>
                  Customer Email
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center' style={Style.tableHeader} width={1}>
                  Amount
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center' style={Style.tableHeader}>
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center' style={Style.tableHeader}>
                  Action
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {invoiceList &&
                invoiceList.map((item, index) => {
                  return (
                    <Table.Row key={item.documentSeqCode}>
                      <Table.Cell textAlign='center'>{item.documentSeqCode}</Table.Cell>
                      <Table.Cell textAlign='center'>{item.customerEmail}</Table.Cell>
                      <Table.Cell textAlign='right'> {formatNumberWithCurrency(item.totalAmount, decimalScale, numberFormat)}</Table.Cell>
                      <Table.Cell textAlign='center'>{item.status}</Table.Cell>
                      <Table.Cell textAlign='center'>
                      {item.invoiceLink == null ? (
                        <button className='btn' onMouseDown={handleSend} value={index}>
                          <u>Send</u>
                        </button>
                      ) : (
                        <a className='btn' href={item.invoiceLink} target='_blank' rel='noreferrer'>
                          <u>Invoice</u>
                        </a>
                      )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Dimmer.Dimmable>
          <div
            style={{
              flex: 1,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center"
            }}>
            <Pagination
              activePage={pageNumber + 1}
              onPageChange={(event, data) => {
                setPageNumber(data.activePage - 1);
              }}
              totalPages={totalPages}
            />
          </div>
        </div>
      )}
    </div>
  );

  let {decimalScale, numberFormat} = currentUser;
  return (
    <>
      {invoiceActive && <Overlay />}
      {invoiceActive && getInvoiceModal()}
      <div className='mt-5' style={{width: 840, margin: "auto"}}>
        <div
          className='card'
          style={{color: "#192038", border: "none", boxShadow: "0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)"}}>
          <div className='card-header px-5' style={{background: "white"}}>
            <h5 className='m-0'>Invoices Due</h5>
          </div>
          <div className='card-body px-5'>
            <p>
              Map your Invoices in Stripe with the Deskera Sales Invoices so that Accounting records are synced
              appropriately. For e.g. if you have a invoice "INVOICE-01" placed in your Shop. "INVOICE-01" will also
              appear in Deskera as a Sales Invoice after the sync.
            </p>
            {invoiceTable()}
          </div>
        </div>
      </div>
    </>
  );
}

export default Invoices;
