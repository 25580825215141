import {Link} from "react-router-dom";
import {getMenuItems} from "src/routes";

function ProgressNav({current}) {
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
      {getMenuItems().map(function (item, index) {
        return (
          <>
            {index < current ? (
              <Link to={item.href} style={{textDecoration: "none", color: "black"}}>
                <div
                  className='px-3 py-2'
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 1000,
                    backgroundColor: "white",
                    boxShadow: "0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)"
                  }}>
                  <span className='svg-icon' style={{height: 16, width: 16}}>
                    {item.icon}
                  </span>
                  <span className='ml-2'>{item.title}</span>
                </div>
              </Link>
            ) : (
              <div
                className='px-3 py-2'
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 1000,
                  backgroundColor: "#ececec",
                  color: "gray",
                  boxShadow: "0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)"
                }}>
                <span className='svg-icon svg-disabled' style={{height: 16, width: 16}}>
                  {item.icon}
                </span>
                <span className='ml-2'>{item.title}</span>
              </div>
            )}
            {index < getMenuItems().length - 1 ? (
              <span style={{backgroundColor: "#bbbbbb", width: 100, height: 1}}></span>
            ) : null}
          </>
        );
      })}
    </div>
  );
}

export default ProgressNav;
