function Overlay() {
  return (
    <div
      style={{
        zIndex: 99,
        height: "100vh",
        width: "100vw",
        position: "absolute",
        top: 0,
        left: 0,
        background: "rgba(0, 0, 0, 0.4)"
      }}></div>
  );
}

export default Overlay;
