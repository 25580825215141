import {useSelector} from "react-redux";
import DeskeraLogo from "src/assets/ic_company_logo.png";

function getInitials(name) {
  return name
    .split(" ")
    .map(n => n[0])
    .join("")
    .substring(0, 2);
}

function Header() {
  const currentUser = useSelector(function (state) {
    return state.auth.user;
  });
  return (
    <nav
      className='navbar navbar-light bg-white py-0'
      style={{height: 80, boxShadow: "0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)", justifyContent: "space-between"}}>
      <span className='navbar-brand'>
        <img src={DeskeraLogo} style={{height: 24}} alt='Deskera' />
        <span className='ml-2' style={{fontSize: "0.875rem"}}>
          Stripe Connect
        </span>
      </span>
      <div
        style={{
          backgroundColor: "#304072",
          color: "rgba(256, 256, 256, 0.9)",
          width: 48,
          height: 48,
          boxShadow: "inner 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          borderRadius: 1000,
          fontSize: "1.125rem",
          fontWeight: "bold"
        }}>
        <span>{getInitials(currentUser.name)}</span>
      </div>
    </nav>
  );
}

export default Header;
