import Invoices from "src/pages/Invoices";
import Deskera from "src/pages/Deskera";
import Stripe from "src/pages/Stripe";
import Config from "./pages/Config";
import CreditCardLogo from "src/assets/CreditCard";
import PayLogo from "src/assets/Pay";
import SettingsLogo from "src/assets/Settings";
import HomeLogo from "src/assets/Home";

export function getMenuItems() {
  return [
    {
      title: "Deskera",
      href: "/",
      page: <Deskera />,
      icon: <HomeLogo />
    },
    {
      title: "Stripe",
      href: "/pages/stripe",
      page: <Stripe />,
      icon: <CreditCardLogo />
    },
    {
      title: "Config",
      href: "/pages/config",
      page: <Config />,
      icon: <SettingsLogo />
    },
    {
      title: "Invoices",
      href: "/pages/invoices",
      page: <Invoices />,
      icon: <PayLogo />
    }
  ];
}
