import {useState} from "react";
import {API} from "src/api";

function ConnectModal() {
  async function handleLogin(e) {
    e.preventDefault();
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/redirecturl");
    const redirecturl = resp.data.url;
    window.open(redirecturl);
  }

  return (
    <div>
      <p style={{color: "#192038"}}>
        You need to connect your Deskera Books account first by authenticating, which will sync the Invoices between
        Deskera Books and Stripe Connect app.
      </p>
      <div style={{display: "flex", flexDirection: "row-reverse"}}>
        <button className='btn btn-primary' onMouseDown={handleLogin}>
          Connect
        </button>
      </div>
    </div>
  );
}

function ConnectedModal({updateAccount}) {
  const [error, setError] = useState("");

  async function handleDisonnect(e) {
    e.preventDefault();

    try {
      await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/disconnect");
      updateAccount();
    } catch {
      setError("Failed to disconnect user account");
    }
  }

  return (
    <div style={{color: "#192038"}}>
      <p style={{color: "#192038"}}>Your Deskera Account is Connected</p>
      <div style={{display: "flex", flexDirection: "row-reverse"}}>
        <button className='btn btn-primary' onMouseDown={handleDisonnect}>
          Disconnect
        </button>
      </div>
    </div>
  );
}

function Deskera({account, updateAccount}) {
  return (
    <div className='mt-5' style={{width: 480, margin: "auto"}}>
      <div
        className='card'
        style={{color: "#192038", border: "none", boxShadow: "0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)"}}>
        <div className='card-header px-5' style={{backgroundColor: "transparent"}}>
          <h5 className='m-0'>Connect your Deskera Account</h5>
        </div>
        <div className='card-body px-5'>
          {account ? <ConnectedModal updateAccount={updateAccount} /> : <ConnectModal />}
        </div>
      </div>
    </div>
  );
}

export default Deskera;
