import {API} from "src/api";
import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {useDispatch} from "react-redux";
import {addToast} from "src/redux/actions/toasts";
import querystring from "querystring";
import clsx from "clsx";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Connect({updateConnect}) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const emptyFormData = {
    code: "",
    state: "",
    accountName: ""
  };
  const [formData, setFormData] = useState(emptyFormData);
  const [formErrors, setFormErrors] = useState({});

  useEffect(
    function () {
      const query = new URLSearchParams(location.search);
      setFormData({...formData, code: query.get("code"), state: query.get("state")});
    },
    [location]
  );

  function handleFormChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({...formData, [name]: value});
  }

  async function handleConnect() {
    setFormErrors({});
    let error = false;
    if (formData.accountName === "") {
      setFormErrors(function (formErrors) {
        return {...formErrors, accountName: "Select a name for the account"};
      });
      error = true;
    }
    if (error) {
      return;
    }

    setFormSubmitting(true);
    try {
      await API.get(process.env.REACT_APP_SERVER_URL + "/stripe/connect?" + querystring.stringify(formData));
      updateConnect();
      dispatch(addToast({type: "alert-success", text: "Your Stripe account was connected."}));
      setFormData(emptyFormData);
      history.push("/pages/config");
    } catch {
      dispatch(addToast({type: "alert-danger", text: "Failed to connect Stripe account. Try again."}));
    } finally {
      setFormSubmitting(false);
    }
  }

  return (
    <div className='mt-5' style={{width: 480, margin: "auto"}}>
      <div
        className='card'
        style={{color: "#192038", border: "none", boxShadow: "0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)"}}>
        <div className='card-header px-5' style={{backgroundColor: "transparent"}}>
          <h5 className='m-0'>Connect your Stripe Account</h5>
        </div>
        <div className='card-body px-5'>
          <form>
            <div class='form-group'>
              <label for='accountName' class='form-label'>
                Account Name
              </label>
              <input
                name='accountName'
                type='text'
                class={clsx("form-control", formErrors.accountName && "is-invalid")}
                id='accountName'
                placeholder='Ex. Stripe US Account'
                value={formData.accountName}
                onChange={handleFormChange}
              />
              {formErrors.accountName && (
                <small id='accountName-error' class='form-text text-danger'>
                  {formErrors.accountName}
                </small>
              )}
            </div>
          </form>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary' type='button' onMouseDown={handleConnect} disabled={formSubmitting}>
            {formSubmitting ? (
              <div class='spinner-border spinner-border-sm mx-3' role='status'>
                <span class='sr-only'>Connecting...</span>
              </div>
            ) : (
              "Connect"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Connect;
