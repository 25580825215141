import {useDispatch} from "react-redux";
import jwt_decode from 'jwt-decode';
import { setCurrentUser } from "src/redux/actions/auth";
const {useState, useEffect} = require("react");
const {API, setAuthToken} = require("src/api");

function LoginCheck({children}) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(function () {
    async function checkStatus() {
      try {
        const resp = await API.get(process.env.REACT_APP_LOGIN_STATUS_URL);
        setAuthToken(resp.data.accessToken);
        setLoading(false);
      } catch {
        window.location = process.env.REACT_APP_AUTH_URL;
      }
    }
    checkStatus();
  }, []);

  return <div>{!loading && children}</div>;
}

export default LoginCheck;
