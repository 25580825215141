import {useState} from "react";
import {useDispatch} from "react-redux";
import {API} from "src/api";
import {addToast} from "src/redux/actions/toasts";

function getAccountName(account, index) {
  return account.name ? account.name : "Stripe Account " + (index + 1);
}

function Stripe({accounts, updateAccounts}) {
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  async function handleConnect(e) {
    e.preventDefault();
    try {
      const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/stripe/redirecturl");
      const redirecturl = resp.data.url;
      window.open(redirecturl);
    } catch {
      setError("Failed to redirect user");
    }
  }

  async function handleDisconnect(e) {
    e.preventDefault();
    try {
      await API.get(process.env.REACT_APP_SERVER_URL + "/stripe/disconnect?accountId=" + e.currentTarget.name);
      dispatch(addToast({text: "Your Stripe Account was Disconnected", type: "alert-success"}));
      updateAccounts();
    } catch {
      setError("Failed to disconnect");
    }
  }

  return (
    <>
      <div className='mt-5' style={{width: 480, margin: "auto"}}>
        <div className='card' style={{border: "none", boxShadow: "0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)"}}>
          <div className='card-header px-5' style={{background: "white"}}>
            <h5 className='m-0'>Connect your Stripe Account</h5>
          </div>
          <div className='card-body px-5'>
            <p>
              In this step you need to connect your Stripe account, you will be redirected to the Stripe login page
              after you click on connect. Please use your existing Stripe account or signup for new account before
              starting this process.
            </p>
            <div style={{display: "flex", flexDirection: "row-reverse", justifyContent: "space-between"}}>
              <button className='btn btn-primary' onClick={handleConnect}>
                {accounts.length > 0 ? "Connect Another Account" : "Connect Account"}
              </button>
            </div>
            {accounts.length > 0 && (
              <table className='table' style={{marginTop: 10, border: "1px solid #f4f4f6"}}>
                <thead>
                  <tr>
                    <th>Account</th>
                    <th></th>
                  </tr>
                </thead>
                {accounts.map((account, index) => (
                  <tr>
                    <td style={{verticalAlign: "middle"}}>{getAccountName(account, index)}</td>
                    <td style={{verticalAlign: "middle"}}>
                      <button className='btn btn-outline-secondary btn-sm' onClick={handleDisconnect} name={account.id}>
                        Disconnect
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Stripe;
