import clsx from "clsx";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {API} from "src/api";
import {addToast} from "src/redux/actions/toasts";

function Config({accounts, config, updateConfig}) {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const emptyFormData = {
    stripeFeesAccountCode: config.stripeFeesAccountCode
  };
  const [formData, setFormData] = useState(emptyFormData);
  const [formErrors, setFormErrors] = useState({});
  const history = useHistory();

  function handleFormChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({...formData, [name]: value});
  }

  async function handleFormSubmit(e) {
    e.preventDefault();
    let error = false;
    if (!formData.stripeFeesAccountCode || formData.stripeFeesAccountCode === "") {
      setFormErrors(function (formErrors) {
        return {...formErrors, stripeFeesAccountCode: "Account should be selected"};
      });
      error = true;
    }
    if (error) {
      return;
    }

    setFormSubmitting(true);
    try {
      await API.post(process.env.REACT_APP_SERVER_URL + "/deskera/accounts", formData);
      dispatch(addToast({type: "alert-success", text: "Account config was saved"}));
      updateConfig();
      history.push("/pages/invoices");
    } catch {
      setError("Failed to submit");
    }
    setFormSubmitting(false);
  }

  return (
    <div className='mt-5' style={{width: 480, margin: "auto"}}>
      <div
        className='card'
        style={{color: "#192038", border: "none", boxShadow: "0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)"}}>
        <div className='card-header px-5' style={{backgroundColor: "transparent"}}>
          <h5 className='m-0'>Account Configuration</h5>
        </div>
        <div className='card-body px-5'>
          <p>Select COA which will be used to map Stripe fee for a transaction</p>
          <form>
            <div className='form-group'>
              <select
                onChange={handleFormChange}
                name='stripeFeesAccountCode'
                className={clsx("custom-select", formErrors.stripeFeesAccountCode && "is-invalid")}>
                <option value='' selected={formData.stripeFeesAccountCode === ""}>
                  Select account
                </option>
                {accounts.map(function (account) {
                  return (
                    <option
                      value={account.code}
                      selected={formData.stripeFeesAccountCode === account.code}>
                      {account.name}
                    </option>
                  );
                })}
              </select>
              {formErrors.stripeFeesAccountCode && (
                <small id='stripeFeesAccountCode-error' class='form-text text-danger'>
                  {formErrors.stripeFeesAccountCode}
                </small>
              )}
            </div>
          </form>
          <div className='pt-3' style={{display: "flex", flexDirection: "row-reverse"}}>
            <button className='btn btn-primary' onMouseDown={handleFormSubmit} disabled={formSubmitting}>
              {formSubmitting ? (
                <div class='spinner-border spinner-border-sm mx-3' role='status'>
                  <span class='sr-only'>Sending...</span>
                </div>
              ) : config.stripeFeesAccountCode ? (
                "Update"
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Config;
