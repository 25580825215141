import {CREATE_TOAST, DELETE_TOAST} from "src/redux/types";
import shortid from "shortid";

export function createToast(message, id) {
  return {
    type: CREATE_TOAST,
    message,
    id
  };
}

export function deleteToast(id) {
  return {
    type: DELETE_TOAST,
    id
  };
}

export function addToast(message) {
  const id = shortid.generate();
  return dispatch => {
    dispatch(createToast(message, id));
    setTimeout(() => dispatch(deleteToast(id)), 2500);
  };
}
