import {Route, Switch, useHistory} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import {useEffect, useState} from "react";
import {API} from "./api";
import Header from "src/components/Header";
import ProgressNav from "./components/Stepper";
import Deskera from "./pages/Deskera";
import Config from "./pages/Config";
import Stripe from "./pages/Stripe";
import Invoices from "./pages/Invoices";
import Connect from "./pages/Connect";
import ToastList from "./components/ToastList";
import {useDispatch} from "react-redux";
import {setCurrentUser} from "./redux/actions/auth";

function App() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [config, setConfig] = useState({});
  const [account, setAccount] = useState({});
  const [stripeAccounts, setStripeAccounts] = useState([]);
  const [deskeraAccounts, setDeskeraAccounts] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(4);
  const [loading, setLoading] = useState(true);
  const [invoiceUpdateCounter, setInvoiceUpdateCounter] = useState(0);
  const [stripeAccountUpdateCounter, setStripeAccountUpdateCounter] = useState(0);
  const [connectCounter, setConnectCounter] = useState(0);
  const history = useHistory();

  function updateInvoices() {
    setInvoiceUpdateCounter(invoiceUpdateCounter + 1);
  }

  function updateStripeAccounts() {
    setStripeAccountUpdateCounter(stripeAccountUpdateCounter + 1);
  }

  function updateConnect() {
    setConnectCounter(connectCounter + 1);
  }

  function updateConfig() {
    setConnectCounter(connectCounter + 1);
  }

  useEffect(
    function () {
      const statuses = [true, true, true];
      async function fetchData() {
        const requests = [];
        requests.push(
          API.get(process.env.REACT_APP_SERVER_URL + "/deskera/user")
            .then(function (resp) {
              setAccount(resp.data);
              if (resp.data === "") {
                statuses[0] = false;
              }
            })
            .catch(err => err)
        );
        requests.push(
          API.get(process.env.REACT_APP_SERVER_URL + "/stripe/accounts")
            .then(function (resp) {
              setStripeAccounts(resp.data);
              if (resp.data === "" || resp.data.length === 0) {
                statuses[1] = false;
              }
            })
            .catch(err => err)
        );

        requests.push(
          API.get(process.env.REACT_APP_SERVER_URL + "/deskera/configs")
            .then(function (resp) {
              setConfig(resp.data);
              if (resp.data === "") {
                statuses[2] = false;
              }
            })
            .catch(err => err)
        );

        requests.push(
          API.get(process.env.REACT_APP_SERVER_URL + "/deskera/accounts")
            .then(function (resp) {
              setDeskeraAccounts(resp.data);
            })
            .catch(err => err)
        );

        requests.push(
          API.get(process.env.REACT_APP_TENANT_URL)
            .then(function (resp) {
              dispatch(setCurrentUser(resp.data));
            })
            .catch(err => err)
        );

        try {
          await Promise.allSettled(requests);
          var i = 0;
          while (i < 3 && statuses[i] === true) {
            i++;
          }
          switch (i) {
            case 0:
              break;
            case 1:
              break;
            case 2:
              break;
            default:
              break;
          }
          setCurrentPage(i + 1);
        } catch {
          setError("Failed to load");
        }
        setLoading(false);
      }

      fetchData();
      // eslint-disable-next-line
    },
    [invoiceUpdateCounter, stripeAccountUpdateCounter, connectCounter]
  );

  return (
    !loading && (
      <div style={{display: "flex", flexDirection: "column", height: "100vh", width: "100vw", overflow: "hidden"}}>
        <Header />
        <ToastList />
        <div style={{background: "#f4f4f6", overflowX: "hidden", overflowY: "auto", height: "100vh"}}>
          <div className='p-5'>
            <ProgressNav current={currentPage} />
            <Switch>
              <Route exact path='/'>
                <Deskera account={account} updateAccount={updateConnect} />
              </Route>
              <Route path='/pages/config'>
                <Config accounts={deskeraAccounts} config={config} updateConfig={updateConfig} />
              </Route>
              <Route path='/pages/stripe'>
                <Stripe accounts={stripeAccounts} updateAccounts={updateStripeAccounts} />
              </Route>
              <Route path='/pages/invoices'>
                <Invoices invoices={invoices} updateInvoices={updateInvoices} />
              </Route>
              <Route path='/pages/deskera'>
                <Connect updateConnect={updateConnect} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    )
  );
}

export default App;
