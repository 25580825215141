import {SET_CURRENT_USER} from "src/redux/types";
import {isEmpty} from "lodash";

const initialState = {
  isAuthenticated: false,
  user: {}
};

function authReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        isAuthenticated: !isEmpty(action.user),
        user: action.user
      };
    default:
      return state;
  }
}

export default authReducer;
