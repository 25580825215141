import {CREATE_TOAST, DELETE_TOAST} from "src/redux/types";

function toastsReducer(state = [], action = {}) {
  switch (action.type) {
    case CREATE_TOAST:
      return [
        ...state,
        {
          id: action.id,
          type: action.message.type,
          text: action.message.text
        }
      ];
    case DELETE_TOAST:
      const index = state.findIndex(message => message.id === action.id);
      if (index >= 0) {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      } else {
        return state;
      }
    default:
      return state;
  }
}

export default toastsReducer;
