import React from "react";
import {useSelector} from "react-redux";
import clsx from "clsx";

function ToastList() {
  const messages = useSelector(state => state.toasts).map(message => (
    <div
      class={clsx("alert", "px-4", "py-3", message.type)}
      style={{border: "none", boxShadow: "0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1)"}}>
      <span>{message.text}</span>
    </div>
  ));
  return <div class='toast-list'>{messages}</div>;
}

export default ToastList;
